import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { useSiteMetadata } from "../../hooks/SiteMetadataQuery";
import { Layout } from "../../components/Layout";
import { GridLink, GridLinkOverLay } from "../../components/GridLink";
import { PageSeo } from "../../components/Seo";
import { InviteProcessSection } from "../../components/InviteProcessSection";
import { FinishingTouchesSection } from "../../components/FinishingTouchesSection";
import { TestimonialsSection } from "../../components/TestimonialsSection";
import { Container, Heading, Section } from "../../components/Page";
import { Copy } from "../../components/Copy";

const HouseCollectionPage = () => {
  const { getAQuoteUrl } = useSiteMetadata();
  const { allMarkdownRemark: { edges } } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {fields: {collection: {eq: "house-collection"}}}
        sort: {fields: fileAbsolutePath}
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              thumbnail {
                childImageSharp {
                  gatsbyImageData (
                    width: 600
                  )
                }
              }
            }
          }
        }
      }
    }`);
  return (
    <Layout>
      <PageSeo tabTitleSegments={["House Collection"]} title="House Collection" />
      <Container>
        <div className="mx-auto max-w-narrow">
          <Heading>House Collection</Heading>
          <Copy>
            <p>
              Pre-designed wedding invitations are perfect for personalisation.
              If you see a design that fits your theme, why mess with what is already to your taste?
              Invites range from floral and botanical designs in watercolour or fine line drawings.
              Some designs can also be altered to match your colour theme.
              For example, you could add finishing touches such as envelope colour, ribbon, wax seals, with or without vellum...
            </p>
            <p>
              These designs are sometimes pictured with optional finishing touches so you can get an idea as to how they can look.
            </p>
            <p>
              If you see something you like, please get in touch to via the <a className="font-bold" rel="noreferrer" target="_blank" href={getAQuoteUrl}> quote form</a>.
            </p>
          </Copy>
        </div>
        <Section>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {edges.map(({ node: { fields: { slug }, frontmatter: { title, thumbnail } } }) =>
              <HouseCollectionItem key={slug} slug={slug} title={title} thumbnail={thumbnail} />)}
          </div>
        </Section>
        <InviteProcessSection />
        <FinishingTouchesSection />
        <TestimonialsSection />
      </Container>
    </Layout>
  );
};

const HouseCollectionItem = ({ slug, title, thumbnail }) => {
  const image = getImage(thumbnail);
  return (
    <GridLink as="h2" to={slug} className="h-[150px] sm:h-[300px]">
      <GatsbyImage image={image} alt={title} />
      <GridLinkOverLay className="text-xl sm:text-lg">{title}</GridLinkOverLay>
    </GridLink>
  );
};

export default HouseCollectionPage;
